import React, { useEffect } from 'react';
import {LoginPageStyled, LoginStyled} from "../LoginPage/login-page.styles";
import Header from "../_components/Header/Header";
import {newsletterSubscribeActions} from "../_actions/newsletter-subscription.action";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";

function DestroyPage({remove}){


    const { hash } = useParams()

    useEffect(() => {
        document.body.classList.add('background-green')
        remove(hash);

    }, [hash, remove])
    return (
        <LoginPageStyled>
            <Header whiteLogo={true} />
            <LoginStyled>
                <div className='greeting-login'>
                    <p>Törlés folyamatban...</p>
                </div>
            </LoginStyled>


        </LoginPageStyled>
    );
}

function mapState() {
    return null;
}

const actionCreators = {
    remove: newsletterSubscribeActions.remove,

};

const connectedDestroyPage = connect(mapState, actionCreators)(DestroyPage);
export { connectedDestroyPage as DestroyPage };

