import styled from 'styled-components'
import { variables } from "../../variables.styles"

export const HeaderStyled = styled.div`
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
   ${variables.mediaQueries.max600} {
        justify-content: flex-end;
    }
    img {
        height: 100%;
        margin-left: 450px;
        ${variables.mediaQueries.max600} {
            margin-left: 0;
            margin-right: 15px;
        }
    }
`