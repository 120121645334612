export const variables = {
    colors: {
        green: "#00AF8E",
        greenDark: "#006250",
        greenDarkest: "#016250",
        greenLight: "#02D2AA",
        greenBg: "#00AD8D",
        gray: "#7C807F"
    },
    global: {
        paddingDesktopGlobal: "60px",
        paddingMobileGlobal: "20px"
    },
    breakpoints: {
        small: "0",
        medium: "600px",
        xmedium: "850px",
        large: "1024px",
        xlarge: "1200px",
        xxlarge: "1440px"
    },
    mediaQueries : {
        max600: "@media (max-width: 600px)",
        btw400and600: "@media (max-width: 600px) and (min-width: 400px)",
        max400: "@media (max-width: 400px)",

    }
}