import React, { useEffect } from 'react'
import {SubscribeForm} from '../_components/SubscribeForm/SubscribeForm'
import { SubscribePageStyled, SubscribeStyled } from "./subscribe-page.styles"
import Header from "../_components/Header/Header"

const SubscribePage = () => {
    useEffect(() => {
        document.body.classList.remove('background-green')
        document.body.classList.add('background-white')
    }, [])
    return(
        <SubscribePageStyled>
            <Header />
            <SubscribeStyled>
                    <p className='greeting-subscribe'>Ha szeretne elsőkézből értesülni a JTI márkáival kapcsolatos friss hírekről, aktuális témákról, valamint az olyan, iparágat érintő fontos üzleti történésekről mint az egyencsomagolás bevezetése, iratkozzon fel hírlevél listánkra:</p>
                <SubscribeForm />
            </SubscribeStyled>
        </SubscribePageStyled>
    )
}

export default SubscribePage
