import React, { useEffect } from 'react';
import {LoginPageStyled, LoginStyled} from "../LoginPage/login-page.styles";
import Header from "../_components/Header/Header";

function PrivacyPage(){
    useEffect(() => {
        document.body.classList.remove('background-white')
        document.body.classList.add('background-green')
    }, [])
    return (
        <LoginPageStyled>
            <Header whiteLogo={true} />
            <LoginStyled>
                <div className='greeting-login'>
                    <p>
                        <a href="/JTI_Hungary_HU_dohany_kiskereskedelmi_adatke_zelesi_tajekoztato_20211125_v1_1.pdf">Adatkezelési tájékoztató</a>
                    </p>
                </div>
            </LoginStyled>

        </LoginPageStyled>
    );
}

export default  PrivacyPage;
