import axios from "axios";
import { authHeader } from '../_helpers';
export const newsletterSubscriptionService = {
    subscribe,
    validate,
    remove,
};

function subscribe(object) {
    return axios
        .post(process.env.REACT_APP_API_ENDPOINT + "newsletter_subscriptions", object, {
            headers: { ...authHeader() },
        })
}

function validate(hash) {
    return axios
        .put(process.env.REACT_APP_API_ENDPOINT + `newsletter_subscriptions/${hash}`, {
            activatedAt: new Date()
        })
        .then((response) => {
            return response.data;
        }).catch(err => {
            console.log(err);
        })
}
function remove(hash) {
    return axios
        .delete(process.env.REACT_APP_API_ENDPOINT + `newsletter_subscriptions/${hash}`)
        .then((response) => {
            return response.data;
        })
}

