import styled, { css } from 'styled-components'
import { variables } from "../../variables.styles"

const invertedButtonStyles = css`
  background-color: white;
  color: ${variables.colors.greenDarkest};
  width: 130px;
  border: none;
`

const disabledButtonStyles = css`
    cursor: none;
    opacity: .2;
`

const modalButtonStyles = css`
    width: auto;
    font-size: 20px;
    background-color: ${variables.colors.greenBg};
`
const generalButtonStyles = css`
      width: 200px;
      height: 42px;
      font-size: 18px;
      font-weight: normal;
`

const invertedImpressumButtonStyles = css`
        color: white;
`

const getButtonBaseStyles = props => {
    return props.isModal ? modalButtonStyles : generalButtonStyles
}
const getButtonStyles = props => {
    return props.inverted ? invertedButtonStyles : null
}
const getImpressumButtonStyles = props => {
    return props.inverted ? invertedImpressumButtonStyles : null
}
const getDisabledStyles = props => {
    return props.disabled ? disabledButtonStyles : null
}
const getCookieInfoButtonStyles = props => {
    return props.inverted ? '#ffffff' : '#00AF8E'
}

export const ButtonStyled = styled.button`
      position: relative;
      font-weight: bold;
      cursor: pointer;
      text-transform: uppercase;
      background-color: ${variables.colors.greenDark};
      border: none;
      color: white;
      margin-top: 1em;
  ${getButtonBaseStyles}
  ${getButtonStyles}
  ${getDisabledStyles}
`

export const ImpressumButtonStyled = styled.span`
      cursor: pointer;
      font-size: 14px;
      font-weight: normal;
      text-decoration: underline;
      margin-top: 1em;
      display:inline-block
  ${getImpressumButtonStyles}
`

export const CookieInfoButtonStyled = styled.span`
      cursor: pointer;
      font-size: 14px;
      font-weight: normal;
      text-decoration: underline;
      margin-top: 1em;
      display:inline-block;
      a {color: ${getCookieInfoButtonStyles}}
`

