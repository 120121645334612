import styled from 'styled-components'
import { variables } from '../../../variables.styles'

export const CheckboxStyled = styled.div`
    
`
export const LabelStyled = styled.div`
    ${variables.mediaQueries.btw400and600} {
    }
    a {
        color: inherit;
        text-decoration: underline;
        cursor: pointer;
    }
`

export const CheckboxHorizontalPosition = styled.div`
    position: relative;
    margin-top: 3rem;
    input[type="checkbox"] {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 18px;
        width: 18px;
        margin-top: 2px;
    }
    label {
        position: relative;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 0;
        padding-left: 30px;
        font-size: 13px;
        line-height: 1.2;
    }
    label:before, label:after {
        pointer-events: none;
    }
    label:before {
        position: absolute;
        content: ' ';
        left: 0;
        top: 0;
        height: 20px;
        width: 20px;
        border: solid 1px ${variables.colors.greenLight};
        background: #fff;
        margin-right: 10px;
    }
    label:after {
        position: absolute;
        top: 5px;
        left: 5px;
        display: flex;
        content: ' ';
        height: 10px;
        width: 10px;
        background: ${variables.colors.greenLight};
        transform: scale(0);
        transition: transform .1s ease;
    }
    input[type="checkbox"]:checked + div {
         label:after {
            transform: scale(1);
         }
    }
`