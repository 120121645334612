import { newsletterSubscriptionConstants } from "../_constants/newsletter-subscription.constants"

const initialState = {
    formValues: {
        name: '',
        email: '',
        code: '',
        job: '',
        phone: ''
    },
    isSubmitting: false,
    isSubmitted: false,
    successful: false,
}

export const newsletterSubscription = (state = initialState, action) => {
    switch (action.type) {

        case newsletterSubscriptionConstants.SUBSCRIBE_SET_FORM_VALUES:

            return {
                ...state,
                formValues: {
                    ...state.formValues,
                    [action.object.name]: action.object.value
                }
            };

        case newsletterSubscriptionConstants.SUBSCRIBE_RESET_FORM_VALUES:
            return initialState;

        case newsletterSubscriptionConstants.SUBSCRIBE_REQUEST:
            return {
                ...state,
                isSubmitting: true,
                payload: action.object
            }
        case newsletterSubscriptionConstants.SUBSCRIBE_SUCCESS:
            return {
                ...state,
                isSubmitted: true,
                isSubmitting: false,
                successful: true,
            }
        case newsletterSubscriptionConstants.SUBSCRIBE_FAILURE:
            return {
                ...state,
                isSubmitted: false,
                isSubmitting: false,
                payload: action.error
            }
        default:
            return state
    }
}
