import styled from 'styled-components'
import { variables } from "../../../variables.styles"

export const SelectStyled = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    width: 100%;
    label {
        display: block;
        margin-bottom: .5rem;
        font-size: 18px;
    }
    select {
        appearance: none;
        outline: none;
        cursor: pointer;
        border-radius: 0;
        border: 1px solid ${variables.colors.greenLight};
        border-radius: 0px;
        width: 100%;
        padding: 7px;   
        box-sizing: border-box;
        font-size: 19px;
        font-weight: normal;
        color: ${variables.colors.gray};
        
    }
    select::after {
      content: "";
      width: 0.8em;
      height: 0.5em;
      background-color: ${variables.colors.gray};
      clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    }
`