import React, { useEffect } from 'react';
import {LoginPageStyled, LoginStyled} from "../LoginPage/login-page.styles";
import Header from "../_components/Header/Header";
import {newsletterSubscribeActions} from "../_actions/newsletter-subscription.action";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";

function ValidatePage({validate}){

    const { hash } = useParams()

    useEffect(() => {
        document.body.classList.add('background-green')
        validate(hash);
    }, [hash, validate])
    return (
        <LoginPageStyled>
            <Header whiteLogo={true} />
            <LoginStyled>
                <div className='greeting-login'>
                    <p>Ellenőrzés folyamatban...</p>
                    <p>{hash}</p>
                </div>
            </LoginStyled>


        </LoginPageStyled>
    );
}

function mapState() {
    return null;
}

const actionCreators = {
    validate: newsletterSubscribeActions.validate,

};

const connectedValidatePage = connect(mapState, actionCreators)(ValidatePage);
export { connectedValidatePage as ValidatePage };

