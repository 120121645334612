import { newsletterSubscriptionService } from "../_services/newsletter-subscription-service"
import { newsletterSubscriptionConstants } from "../_constants/newsletter-subscription.constants"
import {alertActions} from "./alert.actions";
import {userActions} from "./user.actions";

export const newsletterSubscribeActions = {
    subscribe,
    validate,
    remove
};

function subscribe(object, resetForm) {
    return dispatch => {
        dispatch(request({ object }));
        newsletterSubscriptionService.subscribe(object)
            .then(
                response => {

                    dispatch(success());
                    resetForm();
                    dispatch(alertActions.success('Köszönjük, hogy feliratkozott, visszaigazoló mailt küldünk a megadott e-mail címre, melyben található gombra kattintva véglegesítheti regisztrációját!'));
                    }
            )
            .catch(error => {

                if (error.response) {
                    if (error.response.status === 401) {

                        dispatch(userActions.logout);
                    }

                    const {violations} = error.response.data;
                    if (violations.length) {
                        dispatch(alertActions.error(violations[0].message));
                        dispatch(failure());

                    }
                }


            })

    };

    function request(object) { return { type: newsletterSubscriptionConstants.SUBSCRIBE_REQUEST, object } }
    function success(object) { return { type: newsletterSubscriptionConstants.SUBSCRIBE_SUCCESS, object } }
    function failure(error) { return { type: newsletterSubscriptionConstants.SUBSCRIBE_FAILURE, error } }
}

function validate(hash) {
    return dispatch => {
        dispatch(request({ hash }));

        newsletterSubscriptionService.validate(hash).then(
            hash => {
                dispatch(success());
                dispatch(alertActions.success('A feliratkozását aktiváltuk!'));
            },
            error => {
                dispatch(alertActions.error(error.toString()));
                dispatch(failure(error.toString()));

            }

        )
    }

    function request(object) { return { type: newsletterSubscriptionConstants.SUBSCRIBE_REQUEST, object } }
    function success(object) { return { type: newsletterSubscriptionConstants.SUBSCRIBE_SUCCESS, object } }
    function failure(error) { return { type: newsletterSubscriptionConstants.SUBSCRIBE_FAILURE, error } }

}
function remove(hash) {
    return dispatch => {
        dispatch(request({ hash }));

        newsletterSubscriptionService.remove(hash).then(
            hash => {
                dispatch(success());
                dispatch(alertActions.success('A feliratkozását töröltük!'));
            },
            error => {
                dispatch(alertActions.error(error.toString()));
                dispatch(failure(error.toString()));

            }

        )
    }

    function request(object) { return { type: newsletterSubscriptionConstants.SUBSCRIBE_REQUEST, object } }
    function success(object) { return { type: newsletterSubscriptionConstants.SUBSCRIBE_SUCCESS, object } }
    function failure(error) { return { type: newsletterSubscriptionConstants.SUBSCRIBE_FAILURE, error } }

}
