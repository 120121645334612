import React from 'react'

import {newsletterSubscribeActions} from "../../_actions/newsletter-subscription.action"
import {connect} from "react-redux"

import { Formik, Form } from 'formik'
import FormikControl from '../FormElements/FormikControl.jsx'
import Button from '../Button/Button'
import * as Yup from 'yup'
import 'yup-phone'
import Modal from 'react-modal'
import { ImpressumButton}  from "../Button/ImpressumButton";
import CookieInfoButton from "../Button/CookieInfoButton";

Modal.setAppElement('#app')

const initialValues = {
    name: '',
    email: '',
    code: '',
    job: '',
    phone: '',
    checkbox: false,
}

const validationSchema = Yup.object().shape({
    email: Yup.string().email("Érvénytelen e-mail cím.").required("A mező kitöltése kötelező."),
    name: Yup.string().max(500).required("A mező kitöltése kötelező."),
    code: Yup.string().max(500).required("A mező kitöltése kötelező."),
    checkbox: Yup.bool().oneOf([true], "Feliratkozáshoz fogadja el az adatkezelési tájékoztatót."),

})

const dropdownOptions = [
    {key: 'Válasszon...', value: ''},
    {key: 'Tulajdonos', value: 'Tulajdonos'},
    {key: 'Üzletvezető', value: 'Üzletvezető'},
    {key: 'Alkalmazott', value: 'Alkalmazott'}
]

const SubscribeForm = ({subscribe, isSubmitted, isSubmitting}) => {


    return(
        <Formik
            initialValues={initialValues}
            onSubmit={(values, onSubmitProps) => {
                const { name, email, code, job, phone } = values
                subscribe({ name, email, code, job, phone }, onSubmitProps.resetForm)

            }}
            validationSchema={validationSchema}
            validateOnMount
        >
            {(formik) => {
                return(
                    <>
                        <Form className={formik.errors && formik.dirty ? 'isEroor' : null}>
                            <FormikControl control='input' type='text' name='name' label='Az Ön vezeték- és keresztneve:*' />
                            <FormikControl control='input' type='email' name='email' label='Az Ön e-mail címe, amelyre a hírleveleket kéri:*' />
                            <FormikControl control='input' type='text' name='code' label='A trafik kódja (üzlet koncessziós száma):*' otherText='(Ha Ön több trafikkal rendelkezik, elegendő csak az egyik kód megadása.)' />
                            <FormikControl control='select' name='job' label='Az Ön beosztása:*' options={dropdownOptions} />
                            <FormikControl control='input' type='text' name='phone' label='Az Ön telefonszáma:' />
                                <FormikControl control='checkbox' tyupe='checkbox' name='checkbox'  />
                                <Button type='submit' disabled={!(formik.isValid) || isSubmitting} loader={isSubmitting} label='FELIRATKOZOM' />

                            <ImpressumButton height='800px' width='600px' />
                            <CookieInfoButton />
                        </Form>

                    </>
                )
            }}
        </Formik>
    )
}

function mapState(state) {
    const { isSubmitted, isSubmitting } = state.newsletterSubscription;
    return { isSubmitted, isSubmitting };
}

const actionCreators = {
    subscribe: newsletterSubscribeActions.subscribe,

};

const connectedSubscribeForm = connect(mapState, actionCreators)(SubscribeForm);
export { connectedSubscribeForm as SubscribeForm };
