import React from 'react'
import { Field, ErrorMessage } from "formik"
import TextError from "../text-error/TextError"
import { InputStyled } from "./input.styles"

const Input = (props) => {
    const { label, name, otherText, ...rest } = props
    return(
        <InputStyled>
            <label htmlFor={name}>{label}</label>
            { otherText ? <span>{otherText}</span> : null }
            <Field id={name} name={name} {...rest} />
            <ErrorMessage name={name} component={TextError} />
        </InputStyled>
    )
}

export default Input