import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { history } from '../_helpers';
import { alertActions } from '../_actions';
import { PrivateRoute } from '../_components';
import { LoginPage } from '../LoginPage';
import { RegisterPage } from '../RegisterPage';
import SubscribePage from "../SubscribePage/SubscribePage";
import Modal from "react-modal";
import {ModalContentStyled} from "../_components/SubscribeForm/subscribe-form.styles";
import Button from "../_components/Button/Button";
import {ValidatePage} from "../ValidatePage";
import {DestroyPage} from "../DestroyPage";
import PrivacyPage from "../PrivacyPage/PrivacyPage";

function App({alert, clearAlerts}) {

    history.listen(() => {
        // clear alert on location change
        clearAlerts();
    });

    return (
        <>
            {alert.message && (
                <>
                    <Modal
                        isOpen={true}
                        onRequestClose={() => clearAlerts()}
                        style={{
                            content: {
                                margin: 'auto',
                                maxWidth: alert.width ?? '320px',
                                maxHeight: alert.height ?? '400px',
                                border: 'none',
                                borderRadius: '0px',
                                padding: '0px',
                                width: 'calc(100% - 80px)',
                                height: 'auto',
                                overflow: 'auto'

                            },
                            overlay: {
                                backgroundColor: 'rgba(255, 255, 255, 0.4)'
                            },
                        }}
                    >
                        <ModalContentStyled>
                            <Button isModal={true} type='button' label=' X ' onClick={() => clearAlerts()}/>
                            <p>{alert.message}</p>
                        </ModalContentStyled>
                    </Modal>
                </>
            )}
            <Router history={history}>
                <Switch>
                    <Route path="/" component={PrivacyPage} />
                    <PrivateRoute exact path="/" component={SubscribePage} />
                    <Route path="/login" component={LoginPage} />
                    <Route path="/register" component={RegisterPage} />
                    <Route path="/validate/:hash" component={ValidatePage} />
                    <Route path="/destroy/:hash" component={DestroyPage} />
                    <Redirect from="*" to="/login" />
                </Switch>
            </Router>
        </>
    );
}

function mapState(state) {
    const { alert } = state;
    return { alert };
}

const actionCreators = {
    clearAlerts: alertActions.clear
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };
