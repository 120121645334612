import styled from 'styled-components'
import { variables } from '../../../variables.styles'

export const InputStyled = styled.div`
  margin-top: 3rem;
  width: 100%;
  position: relative;
  label {
    display: block;
    margin-bottom: .5rem;
    font-size: 18px;
  }
  input, textarea {
    border-radius: 0;
    border: 1px solid ${variables.colors.greenLight};
    width: 100%;
    padding: 7px;   
    box-sizing: border-box;
    font-size: 19px;
    font-weight: normal;
    color: ${variables.colors.gray};
  }
  span {
    font-size: 13px;
  }
  input:focus, textarea:focus, select:focus {
    outline: none;
  }
  input {
    height: 35px;
  }
  textarea {
    resize: none;    
    height: 142px;
  }
`
