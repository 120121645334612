import React from 'react'
import { TextErrorStyled, TextErrorCheckboxStyled } from './text-error.styles'

const TextError = (props) => {
    return(
        <>
            {
                props.checkbox ? <TextErrorCheckboxStyled>{props.children}</TextErrorCheckboxStyled> : <TextErrorStyled>{props.children}</TextErrorStyled>
            }
        </>

    )
}

export default TextError