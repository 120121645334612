import React from 'react'
import { Field, ErrorMessage } from "formik"
import TextError from "../text-error/TextError"
import { SelectStyled } from "./select.styles";

const Select = (props) => {
    const { label, name, options, ...rest } = props
    return(
        <SelectStyled>
            <label htmlFor={name}>{label}</label>
            <Field as='select' id={name} name={name} {...rest}>
                {
                    options.map(option => {
                        return(
                            <option key={option.value} value={option.value}>{option.key}</option>
                        )
                    } )
                }
            </Field>
            <ErrorMessage name={name} component={TextError} />
        </SelectStyled>
    )
}

export default Select