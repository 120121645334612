import styled from 'styled-components'
import { variables } from "../../variables.styles"

export const ModalContentStyled = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${variables.colors.greenBg};
    button {
        position: absolute;
        top: 5px;
        right: 5px;
        margin-top: 0;
    }
    p {
        padding: 0 25px;
        text-align: center;
        color: white;
    }
    p.impressum-paragraph {
        text-align: left;
        font-family: Akzidenz, sans-serif;
        font-size: 18px;
    }
    .impressum-content {
        margin
    }
`

export const DivCenterPosition = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
        font-family: Akzidenz, sans-serif;
        font-size: 18px;
        align-self: center;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
`
