import React, { useEffect } from 'react';
import {LoginPageStyled, LoginStyled} from "./login-page.styles";
import Header from "../_components/Header/Header";
import { LoginForm } from "../_components/LoginForm";
//import CookieConsent from "react-cookie-consent";
//import Pdf from './jticookie.pdf'

function LoginPage(){
    useEffect(() => {
        document.body.classList.remove('background-white')
        document.body.classList.add('background-green')
    }, [])
    return (
        <LoginPageStyled>
            <Header whiteLogo={true} />
            <LoginStyled>
                <div className='greeting-login'>
                    <p>Üdvözöljük!</p>
                    <p>Lépjen be a partnerlevélben található belépési adatokkal:</p>
                </div>
                <LoginForm />
            </LoginStyled>


            {/*
            <CookieConsent
                location="bottom"
                buttonText="OK"
                cookieName="JTI cookie"
                style={{ background: "#006250", fontSize: "18px" }}
                buttonStyle={{ color: "#016250", background: "#fff", fontSize: "18px" }}
                expires={0}
            >
                EZ A WEBHELY SÜTIKET HASZNÁL - A JTIhirlevel.hu weboldal sütit és más, hasonló technológiákat (együttesen „sütiket”) használ, hogy biztonságos böngészés mellett a legjobb felhasználói élményt nyújtsa. Ha bővebb információkat szeretne e sütik használatáról és arról, hogyan módosíthatja a beállításokat, <a href={Pdf} target="_blank" rel="noreferrer" >kattintson ide a részeletes süti tájékoztatóért</a>!
            </CookieConsent>
*/}
        </LoginPageStyled>
    );
}

export { LoginPage };
