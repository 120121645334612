import React from 'react'
import * as PropTypes from "prop-types";
import {HeaderStyled} from "./header.styles";
import logoWhite from '../../_assets/images/logo-white.png'
import logoGreen from '../../_assets/images/logo-green.png'

const Header = ({ whiteLogo }) => {
    return(
        <HeaderStyled hasWhiteLogo={whiteLogo ? true : false} >
            {
                whiteLogo ? <img alt='logo' src={logoWhite} /> : <img alt='logo' src={logoGreen} />
            }
        </HeaderStyled>
    )
}

Header.propTypes = {whiteLogo: PropTypes.bool};

export default Header

