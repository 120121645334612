import styled from 'styled-components'

export const LoginStyled = styled.div`
    margin: 50px auto;
    max-width: 430px;  
    padding: 0 15px 100px;
    .greeting-login {
        margin-top: 60px;
    }
    
    .greeting-login a {
           color: white;
           &:hover {
           text-decoration: underline;
           }
           text-transform: none;
        }

`

export const LoginPageStyled = styled.div`
    height: 100%;
    color: white;
`
