import styled from 'styled-components'
import {variables} from "../../variables.styles"

export const HorizontalPosition = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    button {
        margin-top: 3rem;
    }
    ${variables.mediaQueries.max600} {
        flex-direction: column;
        align-items: flex-start;
    }
`

export const ReCaptchaStyled = styled.div`
    margin-top: 3rem;
`

