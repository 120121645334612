import { alertConstants } from '../_constants';

export const alertActions = {
    success,
    error,
    clear
};

function success(message, height, width) {
    return { type: alertConstants.SUCCESS, message, height, width };
}

function error(message, height, width) {
    return { type: alertConstants.ERROR, message, height, width };
}

function clear() {
    return { type: alertConstants.CLEAR };
}