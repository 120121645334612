import styled from "styled-components";

export const TextErrorStyled = styled.div`
     color: red;
     font-size: 12px;
     padding-top: .25rem;
     display: inline-block;
     float: right;
     line-height: 1.2;
     padding: 0 .5em;
     margin-top: 3px;
     position: absolute;
     right: 1px;
     margin-top: -.5em;
     background: white;
`

export const TextErrorCheckboxStyled = styled.div`
    color: red;
    font-size: 12px;
    margin-top: .5rem;
    padding: 0 .5em;
    background-color: #fff;
`
