import React, { useRef } from "react"
import { userActions } from "../../_actions"
import { connect } from "react-redux"
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import FormikControl from '../FormElements/FormikControl.jsx'
import Button from '../Button/Button'
import { ReCaptchaStyled } from './login-form.styles'
import ReCAPTCHA from "react-google-recaptcha"
import { DivCenterPosition } from '../SubscribeForm/subscribe-form.styles'
import { ImpressumButton } from "../Button/ImpressumButton"
import CookieInfoButton from "../Button/CookieInfoButton"


const TEST_SITE_KEY = "6LeHcM4aAAAAAFOFHy3r38z4CdTnSoXYJ-n9yoz6"

function LoginForm ({login, loggingIn}) {
    const validationSchema = Yup.object().shape({
        username: Yup.string().max(80).required("A mező kitöltése kötelező."),
        password: Yup.string().required("A mező kitöltése kötelező."),
        recaptcha: Yup.string().required()
    })
    const reRef = useRef()


    return (
        <>
            <Formik
                validationSchema={validationSchema}
                validateOnMount
                onSubmit={(values) => {
                    const {username, password, recaptcha} = values;
                    login(username, password, recaptcha);
                }}
                initialValues ={{
                    username: '',
                    password: '',
                    token: '',
                    recaptcha: ''
                }}

            >
                {(formik) => {
                    return(

                        <Form>
                            <FormikControl control='input' name='username' label='Felhasználónév'/>
                            <FormikControl control='input' type='password' name='password' label='Jelszó' />
                            <ReCaptchaStyled>
                                <ReCAPTCHA
                                    sitekey={TEST_SITE_KEY}
                                    ref={reRef}
                                    onChange={(value) => formik.setFieldValue("recaptcha", value)}
                                    hl='hu'
                                />
                            </ReCaptchaStyled>
                            <DivCenterPosition>
                                <Button type="submit"  label='BELÉPÉS' inverted disabled={!(formik.dirty && formik.isValid) || loggingIn} loader={loggingIn}/>
                            </DivCenterPosition>
                            <ImpressumButton inverted height='800px' width='600px' />
                            <CookieInfoButton inverted />
                        </Form>
                    )
                }}
            </Formik>
            {/*
        <form name="form">
            <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                <label htmlFor="username">Username</label>
                <input type="text" className="form-control" name="username" value={username} onChange={e => setUsername(e.target.value)} />
                {submitted && !username &&
                <div className="help-block">Username is required</div>
                }
            </div>
            <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                <label htmlFor="password">Password</label>
                <input type="password" className="form-control" name="password" value={password} onChange={e => setPassword(e.target.value)} />
                {submitted && !password &&
                <div className="help-block">Password is required</div>
                }
            </div>
            <div className="form-group">
                <Button className="btn btn-primary">Login</Button>
                {loggingIn &&
                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" alt={'loading'} />
                }
                <Link to="/register" className="btn btn-link">Register</Link>
            </div>
        </form>*/}
        </>
    )

}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedLoginForm = connect(mapState, actionCreators)(LoginForm);
export { connectedLoginForm as LoginForm };
