import React from 'react';
import { ButtonStyled } from "./button.styles"


const Button = ({ label, inverted, onClick, type, disabled, isModal, loader }) => (
    <ButtonStyled className={loader ? 'button-loader' : null} inverted={inverted} onClick={onClick} type={type} disabled={disabled} isModal={isModal}>
        <span className='button-text'>{ label }</span>
    </ButtonStyled>
);

export default Button;