import React from 'react'
import Input from './input/Input'
import Checkbox from "./checkbox/Checkbox"
import Select from './select/Select'

 const FormikControl = (props) => {
    const { control, ...rest } = props
     switch(control) {
         case 'input':
             return <Input {...rest} />
         case 'textarea':
         case 'select':
             return <Select {...rest} />
         case 'radio':
         case 'checkbox':
             return <Checkbox {...rest} />
         case 'date':
         default: return null
     }
 }

 export default FormikControl