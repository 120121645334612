import styled from 'styled-components'
import { variables } from '../variables.styles'

export const SubscribeStyled = styled.div`
    margin: 50px auto 100px;
    max-width: 430px;  
    padding: 0 15px 100px;
    span, button {
        align-self: center;
    }
    .greeting-subscribe {
        margin-bottom: 60px;
    }
`

export const SubscribePageStyled = styled.div`
    color: ${variables.colors.green};
`
