import React from 'react';
import { CookieInfoButtonStyled } from "./button.styles"
import Pdf from '../../LoginPage/jticookie.pdf'

const CookieInfo = ({ inverted }) => {
    return (
        <div>
        <CookieInfoButtonStyled inverted={inverted}>
            <span className='button-text'><a href={Pdf} target="_blank" rel="noreferrer">Süti tájékoztató</a></span>
        </CookieInfoButtonStyled>
        </div>
    )
}

export default CookieInfo