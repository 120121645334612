import React from 'react';
import { ImpressumButtonStyled } from "./button.styles"
import {alertActions} from "../../_actions";
import {connect} from "react-redux";

const modalContent = () => {
    return(
        <div className='impressum-content'>
            <p className='impressum-paragraph'>A szolgáltató neve: JTI Hungary Dohányértékesítő Zártkörűem Működő Részvénytársaság</p>
            <p className='impressum-paragraph'>A szolgáltató székhelye: 1095 Budapest, Lechner Ödön fasor 7.</p>
            <p className='impressum-paragraph'>Telefon: 36 1 766 45 22</p>
            <p className='impressum-paragraph'>E-mail: info@jtihirlevel.hu</p>
            <p className='impressum-paragraph'>Nyilvántartó bíróság: Fővárosi Törvényszék</p>
            <p className='impressum-paragraph'>Cégjegyzékszám: 01-10-041665</p>
            <p className='impressum-paragraph'>Adószám: 10591905-2-44</p>
            <p className='impressum-paragraph'>Szakmai érdek-képviselet: Budapesti Kereskedelmi és Iparkamara, 1016 Budapest, Krisztina krt. 99.</p>
            <p className='impressum-paragraph'>Tárhelyszolgáltató: Microsoft Azure (West Europe Datacenter Agriport 601, 1775 TK Middenmeer, Hollandia</p>
        </div>
    )
};

const ImpressumButton = ({ showModal, inverted, height, width }) => (
    <div>
    <ImpressumButtonStyled>
            <span className='button-text' onClick={()=>{showModal(modalContent(), height, width)}}>Impresszum</span>
    </ImpressumButtonStyled>
    </div>
);


const actionCreators = {
    showModal: alertActions.success,
};

const connectedImpressumButton = connect(null, actionCreators)(ImpressumButton);
export { connectedImpressumButton as ImpressumButton };
