import React from 'react'
import { Field, ErrorMessage } from "formik"
import TextError from "../text-error/TextError"
import { LabelStyled, CheckboxStyled, CheckboxHorizontalPosition } from "./checkbox.styles"
import Pdf from '../../adatkezelesi.pdf'

const Checkbox = (props) => {
    const { name, ...rest } = props
    return(
        <CheckboxStyled>
            <CheckboxHorizontalPosition>
                <Field type='checkbox' id={name} name={name} {...rest} />
                <LabelStyled>
                    <label htmlFor={name}>Igen, az  <a href={Pdf} target="_blank" rel="noreferrer">Adatkezelési Tájékoztató</a> ismeretében a hírlevél szolgáltatásra feliratkozom és ezennel HOZZÁJÁRULÁST adok ahhoz, hogy a JTI Hungary Zrt. a fent megadott e-mail címemen – illetőleg telefonszámom megadása esetén telefonos úton is – velem a kapcsolatot felvegye, hogy velem a megjelölt beosztásomnak megfelelően egyéniesített szakmai célú reklámot, friss híreket közöljön aktuális témákról vagy az iparágat érintő fontos üzleti történésekről. Ez a hozzájárulásom bármikor szabadon és korlátozás nélkül visszavonható; e hozzájárulás visszavonása nem érinti a hozzájáruláson alapuló, a visszavonás előtti adatkezelés jogszerűségét. </label>
                </LabelStyled>
            </CheckboxHorizontalPosition>
            <ErrorMessage name={name} component={TextError} checkbox />
        </CheckboxStyled>
    )
}

export default Checkbox
